export default [
  {
    title: 'Orders',
    icon: 'CalendarIcon',
    route: 'order-list',
    action: 'read',
    resource: 'pcp',
  },
  {
    title: 'Production Progress',
    icon: 'TrendingUpIcon',
    route: 'production-progress',
    action: 'read',
    resource: 'pcp',
  },
  {
    title: 'Dashboards',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'Resource Loading',
        route: 'resource-loading',
        icon: 'ChevronRightIcon',
        action: 'read',
        resource: 'pcp',
      },  
      {
        title: 'Schedule Analysis',
        route: 'schedule-analysis',
        icon: 'ChevronRightIcon',
        action: 'read',
        resource: 'pcp',
      },    
      {
        title: 'OEE',
        route: 'oee',
        icon: 'ChevronRightIcon',
        action: 'read',
        resource: 'pcp',
      },
    ],
  },
  {
    title: 'System Setting',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'WorkCenters',
        route: 'workcenters',
        icon: 'ChevronRightIcon',
        action: 'read',
        resource: 'pcp',
      },    
      {
        title: 'Classification',
        icon: 'ChevronRightIcon',
        children: [
          {
            title: 'Refuse Status',
            route: 'refuse-status',
            icon: 'ChevronRightIcon',
            action: 'read',
            resource: 'pcp',
          },
          {
            title: 'Resource Status',
            route: 'resource-status',
            icon: 'ChevronRightIcon',
            action: 'read',
            resource: 'pcp',
          },
        ]

      },
      {
        title: 'Resources',
        route: 'resources',
        icon: 'ChevronRightIcon',
        action: 'read',
        resource: 'pcp',
      },   
      {
        title: 'Sites',
        route: 'sites',
        icon: 'ChevronRightIcon',
        action: 'read',
        resource: 'pcp',
      },
    ]
  },
  {
    title: 'Integration',
    icon: 'Link2Icon',
    children: [
      {
        title: 'Data Source',
        route: 'data-source',
        icon: 'ChevronRightIcon',
        action: 'read',
        resource: 'integration',
      },  
      {
        title: 'Pipelines',
        route: 'pipelines',
        icon: 'ChevronRightIcon',
        action: 'read',
        resource: 'integration',
      },          
      {
        title: 'Log Center',
        route: 'log-center',
        icon: 'ChevronRightIcon',
        action: 'read',
        resource: 'integration',
      },     
      {
        title: 'Download',
        route: 'download-client',
        icon: 'ChevronRightIcon',
        action: 'read',
        resource: 'integration',
      }, 
    ]
  }
]