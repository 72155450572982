<template>
    <b-modal id="modal-change-account" shadow bg-variant="white" backdrop hide-footer centered>

        <template #modal-header>
            <div class="d-flex flex-column align-items-center justify-content-center w-100 title-modal">
                <h5 class="font-style-mca font-weight-600 modal-title">
                    {{ $t('ChangeAccount') }}
                </h5>
                <span class="font-style-mca modal-subtitle">
                    {{ $t('SelectAccountConnected') }}
                </span>
                <button type="button" class="btn-close-modal" @click="closeModal">
                    <IconClose />
                </button>
            </div>
            <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
        </template>

        <div class="line-div"></div>
        <b-form class="container-accounts">
            <b-row>
                <b-col cols="12">
                    <div v-for="(account, index) in infoAccounts"
                        class="d-flex align-items-center justify-content-between line-account"
                        :key="`account-${index + 1}`" :id="`account-${index + 1}`">
                        <div class="d-flex align-items-center container-account">
                            <div class="account-photo">
                                <img :src="account.photo" v-if="account.photo" />
                                <span v-if="!account.photo">
                                    {{ account.first_name[0] }}
                                </span>
                            </div>

                            <div class="d-flex flex-column justify-content-center container-account-info">
                                <span class="account-name">
                                    {{ account.first_name }} {{ account.last_name }}
                                </span>

                                <span class="account-position">
                                    {{ account.job_role }}
                                </span>
                            </div>
                        </div>
                        <div class="container-line-items d-flex">

                            <div class="container-status d-flex justify-content-center align-items-center"
                                v-if="guidAtual == account.guid">
                                <span class="font-style-mca font-weight-600 login-status">
                                    {{ $t('Connected') }}
                                </span>
                            </div>

                            <button type="button" class="btn-line-item d-none d-sm-block" v-b-modal.modal-insert-pin
                                @click="closeModal(account)" v-else>
                                <span class="font-style-mca font-weight-600">
                                    {{ $t('Conect') }}
                                </span>
                            </button>



                            <button type="button" class="btn-line-item d-none d-sm-block"
                                @click="logoutAccount(account.guid)">
                                <span class="font-style-mca font-weight-600">
                                    <IconLogout />
                                </span>
                            </button>

                            <div class="container-dropdown d-block d-sm-none">
                                <b-dropdown class="dropdown-options-account" variant="link"
                                    toggle-class="text-decoration-none" right>
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16"
                                            class="text-body align-middle mr-25" />
                                    </template>

                                    <b-dropdown-item href="#" v-b-modal.modal-insert-pin @click="closeModal(account)">
                                        <IconConnect /> {{ $t('Conect') }}
                                    </b-dropdown-item>
                                    <b-dropdown-item href="#" @click="logoutAccount(account.guid)">
                                        <IconLogout /> {{ $t('Logout') }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-form>

        <div class="line-div"></div>

        <b-form class="d-flex justify-content-center footer-modal">
            <div class="container-btn-enter-other-account">
                <button type="button" class="btn-enter-other-account" @click="logoutChangeAccount">
                    <span class="font-style-mca font-weight-600">
                        {{ $t('EnterWithOtherAccount') }}
                    </span>
                </button>
            </div>
        </b-form>
    </b-modal>

</template>

<script>
import {
    BModal,
    BForm,
    BCol,
    BRow,
    BDropdown,
    BDropdownItem,
    BAvatar
} from 'bootstrap-vue';
import IconLogout from '@/assets/images/pages/logout-icon.svg';
import IconConnect from '@/assets/images/pages/connect-icon.svg';
import IconClose from '@/assets/images/pages/close-button-icon.svg';
import ModalInsertAccountPin from './ModalInsertAccountPin.vue';
import { initialAbility } from '@/libs/acl/config'

export default {
    props: ["logout"],
    components: {
        BModal,
        BForm,
        BCol,
        BRow,
        BDropdown,
        BDropdownItem,
        BAvatar,
        IconLogout,
        IconConnect,
        IconClose,
        ModalInsertAccountPin,
    },
    data() {
        return {
            infoAccounts: [],
            guidAtual: JSON.parse(localStorage.getItem('userData')).guid,
            listagemContextoUsuario: [],
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
        }
    },
    mounted() {
        this.getAccounts()
    },
    methods: {
        getAccounts() {
            this.$http.post(`/api/${this.prefix}/users/logged-info`,
                {
                    guids: JSON.parse(localStorage.getItem('infoAccountsLogin')).map(item => item.guid)

                }).then(response => {
                    this.infoAccounts = response.data
                })
        },
        logoutAccount(guid) {
            let lista = JSON.parse(localStorage.getItem('infoAccountsLogin'));
            let indexGuidAtual = lista.map(item => item.guid).indexOf(guid);
            lista.splice(indexGuidAtual, 1);

            localStorage.setItem('infoAccountsLogin', JSON.stringify(lista));

            if (this.guidAtual == guid) {
                this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/users/logout `,
                    {
                        tokenId: localStorage.getItem('tokenId'),
                        guid: guid

                    }).then(response => {
                        localStorage.removeItem('userData')
                        localStorage.removeItem('sites')

                        // Reset ability
                        this.$ability.update(initialAbility)

                        // Redirect to login page
                        this.$router.push({ name: 'login' }).catch(() => { });
                    })
            }
            else {
                this.getAccounts()
            }
        },
        closeModal(contexto) {
            this.$bvModal.hide('modal-change-account');
            this.$emit('atualizaContextoUsuario', contexto)

        },
        logoutChangeAccount() {
            localStorage.removeItem('userData')
            localStorage.removeItem('sites')

            // Reset ability
            this.$ability.update(initialAbility)

            // Redirect to login page
            this.$router.push({ name: 'login' }).catch(() => { });
        }
    }
}
</script>

<style lang="scss">
@media (min-width:768px) {
    #modal-change-account {
        padding: 0 !important;

        .modal-content,
        .modal-dialog {
            max-width: 746px !important;
        }
    }
}

@media (max-width:480px) {
    #modal-change-account {
        padding: 0 !important;

        .modal-dialog {
            position: absolute;
            bottom: 0;
            width: 100% !important;
            margin: 0 !important;

            .modal-content {
                position: absolute;
                bottom: 0;
                width: 100% !important;
                border-radius: 20px 20px 0px 0px;

                .modal-header {
                    height: 52px;
                    border-radius: 20px 20px 0px 0px;

                    .close {
                        display: none;
                    }

                    .title-modal {
                        flex-direction: row !important;
                        justify-content: space-between !important;
                        box-sizing: border-box;
                        padding: 0 16px;

                        .btn-close-modal {
                            border: none;
                            outline: none;
                            background: none;
                            display: block;

                            svg path {
                                fill: #974900;
                            }
                        }
                    }

                    .modal-title {
                        font-size: 14px;
                    }

                    .modal-subtitle {
                        display: none;
                    }
                }

                .modal-body {
                    padding: 0;

                    .container-accounts {
                        padding-top: 4px;

                        .line-account {
                            padding: 8px 1rem 0 !important;
                            height: 30px !important;

                            .container-account {
                                .account-photo {
                                    min-width: 30px !important;
                                    min-height: 30px !important;
                                    width: 30px !important;
                                    height: 30px !important;

                                    span {
                                        font-size: 15px;
                                    }
                                }

                                .account-name {
                                    font-size: 12px !important;
                                    line-height: 16px !important;
                                }

                                .account-position {
                                    font-size: 10px !important;
                                    line-height: 14px !important;
                                }
                            }

                            .container-line-items {
                                .container-status {

                                    .login-status {
                                        font-size: 12px !important;
                                    }
                                }

                                .dropdown-options-account {
                                    width: 40px;
                                }
                            }
                        }
                    }

                    .footer-modal {
                        height: 60px;

                        .container-btn-enter-other-account {
                            margin: 0;
                            display: flex;
                            align-items: center;
                            width: 90%;

                            .btn-enter-other-account {
                                width: 100%;
                                border: 1px solid #974900;
                                border-radius: 5px;
                                padding: 4px 0;

                                span {
                                    font-size: 12px;
                                }
                            }
                        }

                    }
                }
            }
        }

        .modal-body {
            .btn-line-item {
                display: none;
            }
        }

    }

}

.modal .modal-header .close {
    transform: translate(8px, -2px) !important;
}

#modal-change-account {

    .font-style-mca {
        font-weight: 400;
        line-height: 20px;
    }

    .font-weight-600 {
        font-weight: 600;
    }

    .modal-dialog {
        width: 95%;
        max-width: none;
    }

    .modal-content {
        width: 100%;
        right: 0 !important;
    }

    .modal-header {
        position: relative !important;
        height: 122px;

        .title-modal {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            h5 {
                font-size: 28px;
                line-height: 38px;
                color: #4C4541;
            }

            span {
                font-size: 14px;
                color: #998F8A;
            }

            .btn-close-modal {
                display: none;
            }

            .close {
                &:focus {
                    transform: translate(0px, 0px) !important;

                }

                &:active {
                    transform: translate(0px, 0px) !important;

                }
            }
        }
    }

    .modal-body {
        padding: 0;

        .line-div {
            height: 1px;
            width: 100%;
            margin: 0;
            background: #CFC4BE;
        }

        .container-accounts {
            &::-webkit-scrollbar {
                width: 5px;
                /* width of the entire scrollbar */
                border-radius: 50%;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #FBEEE8;
                /* color of the tracking area */
            }

            &::-webkit-scrollbar-thumb {
                background-color: #CFC4BE;
                /* color of the scroll thumb */
                border-radius: 20px;
                /* roundness of the scroll thumb */
            }

            height: 270px;
            overflow-y: scroll;
            overflow-x: hidden;

            .line-display-none {
                display: none !important;
            }

            .line-account {
                height: 50px;
                box-sizing: initial;
                padding: 10px 40px 0px;

                &:nth-last-child(1) {
                    margin-bottom: 10px !important;
                }

                .container-account {
                    .account-photo {
                        position: relative;
                        overflow: hidden;
                        margin-right: 14px;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background: #FF8D2E;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        span {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            color: #512400;
                            font-size: 22px;
                            font-weight: 700;
                        }
                    }

                    .account-name {
                        font-size: 16px;
                        line-height: 22px;
                        color: #4C4541;
                    }

                    .account-position {
                        font-size: 12px;
                        line-height: 16px;
                        color: #998F8A;
                    }
                }

                .container-line-items {
                    .container-status {
                        width: 80px;

                        .login-status {
                            box-sizing: border-box;
                            padding: 2px 9px;
                            background: #E8F5E9;
                            border-radius: 4px;
                            font-size: 12px;
                            line-height: 16px;
                            color: #1B5E20;
                        }
                    }

                    .btn-line-item {
                        height: 30px !important;
                        margin-left: 8px;
                        box-sizing: border-box;
                        padding: 4px 14px;
                        border: 1px solid #974900;
                        border-radius: 5px;
                        background-color: #fff;
                        transition: 1s;

                        span {
                            font-size: 12px;
                            color: #974900;
                        }

                        svg path {
                            fill: #974900 !important;
                        }

                        &:hover {
                            background-color: rgba(244, 129, 31, 0.35);
                        }

                    }

                    .dropdown-options-account {
                        width: 46px;
                        margin-left: 8px;

                        button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0 !important;

                            &::after {
                                display: none;
                            }
                        }

                        svg circle {
                            color: #974900;
                        }
                    }
                }
            }

        }

        .footer-modal {
            height: 101px;

            .container-btn-enter-other-account {
                margin-top: 15px;

                .btn-enter-other-account {
                    border: none;
                    background-color: #fff;
                    padding: 13px 18px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    transition: 1s;

                    span {
                        font-size: 17px;
                        color: #974900;
                    }

                    &:hover {
                        background-color: rgba(244, 129, 31, 0.35)
                    }
                }
            }

        }

    }

}
</style>