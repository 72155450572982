<template>
  <ul >
    <b-nav-item-dropdown
      link-classes="bookmark-star"
      lazy
      id="dropdown-grouped"
      variant="link"
      
        class="list-itens-sites"
    >
      <template #button-content>
        <IconDatabases />
        <span>Sites</span>
        <IconArrowDown class="arrow-icon"/>
      </template>
      
      <b-dropdown-item
        v-for="site in sites"
        :key="site.guid"
        @click="setSite(site)"
        class="item-site-vertical"
        :style="getItemColor(site, 'item')"
      >
          <span :style="getItemColor(site, 'item')">{{ site.name }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    
    <li class="nav-item mode" >
      <b-link class="d-flex align-items-center" @click="toggleOperatorMode" >
        <IconPCP v-if="isNavMenuHidden" class="icon-pcp-mobile" />
        <feather-icon
          v-else
          size="16"
          icon="GridIcon"
        />
        <span>{{isNavMenuHidden ? $t("PCP Mode") : $t("Operator Mode")}}</span>
      </b-link>
    </li>

    <li class="nav-item" v-b-modal.modal-change-account>
      <b-link class="d-flex align-items-center">
        <IconReload/>
          <span>{{$t("ChangeAccount")}}</span>
      </b-link>
    </li>

    <div class="line"/>

    <template v-if="this.$route.name.split('-')[0] != 'operator'">
      <component
        :is="resolveNavItemComponent(item)"
        v-for="item in items"
        :key="item.header || item.title"
        :item="item"
      />
    </template>
    

    <li class="nav-item">
      <b-link class="d-flex align-items-center" :to="{ name: 'account-setting' }" >
        <IconProfile/>
        <span>{{$t("Profile")}}</span>
      </b-link>
    </li>

    <li class="nav-item">
      <b-link class="d-flex align-items-center" :to="{ name: 'support' }" >
        <IconSupport/>
        <span>{{$t("Support")}}</span>
      </b-link>
    </li>

    <Locale/>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout" class="item-logout">
      <IconLogout/>
      <span>{{$t("Logout")}}</span>
    </b-dropdown-item>
    

  </ul>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import { BLink,BNavItemDropdown, BDropdownItem} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import SiteSelect from '@core/layouts/components/app-navbar/components/SiteSelect.vue'
import IconProfile from '@/assets/images/pages/profile-icon.svg'
import IconSupport from '@/assets/images/pages/support-icon.svg'
import IconArrowDown from '@/assets/images/pages/arrow-down-icon.svg'
import IconDatabases from '@/assets/images/pages/database-icon.svg'
import IconLogout from '@/assets/images/pages/logout-icon.svg'
import IconPCP from '@/assets/images/icons/modo-pcp.svg'
import IconReload from '@/assets/images/pages/reload-icon.svg'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BLink,
    Locale,
    SiteSelect,
    IconProfile,
    IconSupport,
    IconArrowDown,  
    IconDatabases,
    IconLogout,
    IconReload,
    IconPCP
  },
  data(){
    return {
      sites: null,
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))
    const {isNavMenuHidden } = useAppConfig()


    return {
      resolveNavItemComponent, isNavMenuHidden
    }
  },
  created(){
      this.getSites()
  },
  methods: {
    getItemColor(site, type){
      let currentSite = JSON.parse(localStorage.getItem('currentSite'))
      if (currentSite && currentSite.guid == site.guid){
        if(type == 'item'){
          return "background-color: #974900; color: #ffff !important; border-radius: 6px !important;"

        }else if (type == 'icon'){
          return "color:#ffff"
        }else{
          return "color: #ffff !important"
        }
      }
      return "background-color: transparent ; color: #998F8A"
    },
    logout(){
      useJwt.logout(this.$router.currentRoute.params.prefix).then(response =>{
          localStorage.removeItem('userData')
          localStorage.removeItem('sites')

          // Reset ability
          this.$ability.update(initialAbility)

          // Redirect to login page
          this.$router.push({ name: 'login' }).catch(() => {});
      }).catch(error => {
          // Remove accessToken from localStorage
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('sites')

          // Reset ability
          this.$ability.update(initialAbility)
           
          // Redirect to login page
          this.$router.push({ name: 'login' })
      })
    },
    getSites(){
      this.sites = JSON.parse(localStorage.getItem('sites'))
    },
    setSite(site){
        localStorage.setItem('currentSite', JSON.stringify(site))
        this.$router.go()
    },
    toggleOperatorMode(){
      // this.isNavMenuHidden = !this.isNavMenuHidden
      return this.$route.name.split('-')[0] == 'operator' ? this.$router.push({name: 'home', params: {prefix: this.prefix}}) : this.$router.push({name: 'operator', params: {prefix: this.prefix}})
    },
  },
}
</script>
<style lang="scss">
.line{
  width: 100%;
  height: 1px;
  background-color: #CFC4BE;
  margin: 12px 0;
}
.main-menu .navigation .list-itens-sites.show .nav-link {
    background: #FFDBC4 !important;
      svg{
        path{
          stroke: #974900 !important;
        }
      }
      span{
        color: #974900;
      }
      .arrow-icon{
        top: 16px !important;
        margin-right: 25px;
      }

}
.list-itens-sites{
  
  .nav-link{
    position: relative !important;
    .arrow-icon{
      position: absolute !important;
      transform: rotate(270deg);
      float: none !important;
      margin-top: 0 !important;
      right: -18px;
      top: 8px !important;
    }
  }
}
.list-itens-sites,.nav-locale{
  
  a{
    position: relative !important;
    .arrow-icon{
      position: absolute !important;
      transform: rotate(270deg);
      float: none !important;
      margin-top: 0 !important;
      right: -18px;
      top: 8px !important;
    }
  }
  .dropdown-menu{
    position: relative !important;
    margin: 0 !important;
    box-shadow: none;
    border: none;
    width: 88%;
    left: 17px !important;
    li{
      margin: 0 !important;
      margin-top: 0 !important;
      .dropdown-item{
        background: transparent !important;
        padding-left: 45px !important;
        margin-bottom: 4px;
        span{
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #998F8A;

        }
      }
    }
  }
}

.icon-pcp-mobile{
   stroke: none !important;
    path{
      fill: #998F8A !important;
    }
}
</style>
