<template>
    <b-modal
        id="modal-insert-pin"
        shadow
        bg-variant="white"
        backdrop
        hide-footer
        centered
    >
        <div v-if="!showLoading">
            <div class="container-header">
                <div class="d-flex flex-column align-items-center justify-content-center w-100 title-modal" v-if="!showLoading">
                    <div class="account-photo">
                        <img :src="dadosUsuario.photo" v-if="dadosUsuario.photo"/>
                        <span v-if="!dadosUsuario.photo">
                            {{dadosUsuario.first_name[0]}}
                        </span>
                    </div>

                    <span class="font-style-mip modal-subtitle">
                        {{$t('EnterWithPin')}} {{dadosUsuario.first_name}} {{dadosUsuario.last_name}}
                    </span>
                </div>
            </div>
            <div class="line-div" :style="`background: ${pinIncorret ? '#D32F2F' : '#CFC4BE'};`"></div>

            <b-form
                class="container-input-pin"
                autocomplete="off"
            >
                <b-row>
                    <b-col
                        cols="12"
                        class="container-inputs-pin"
                    >
                        <b-form-input
                            id="input-pin"
                            :type="inputType"
                            inputmode="numeric"
                            @keydown="filterInput($event)"
                            @input="maxInput($event)"
                            :value="pin"
                            :key="`input-pin-${keyInputRender}`"
                            maxlength="4"
                            autofocus
                            autocomplete="new-password"
                            name="new-password"
                            @click="setCursorInputFinal"
                        />

                        <div class="input-pin-helper">

                            <span
                                :class="`font-style-mip font-weight-600 ${pinIncorret ? 'pin-invalid' : ''}`"
                                v-if="pinIncorret && pin != ''"
                            >
                                {{pin}}
                            </span>
                            <span
                                v-if="!pinIncorret || (pin == '')"
                                :class="`mask-input
                                    ${
                                        pin != '' ? 'color-mask-pin'
                                        : !pinIncorret ? 'color-mask-placeholder'
                                        : 'color-mask-placeholder --placeholder-invalid'
                                    }`"
                            >
                                {{maskInput}}
                            </span>
                        </div>
                    </b-col>
                </b-row>
            </b-form>

            <div class="line-div" :style="`background: ${pinIncorret ? '#D32F2F' : '#CFC4BE'};`"></div>

            <b-form class="d-flex align-items-center justify-content-center footer-modal">
                <div class="container-btn-enter-other-account d-flex flex-column align-items-center">
                    <span
                        class="font-style-mip alert-pin"
                        v-if="pinIncorret"
                    >
                        {{$t('PINInvalid')}}
                    </span>

                    <div class="block-inv" v-if="pinIncorret"></div>

                    <button
                        type="button"
                        class="btn-enter-other-account"
                        @click="selectOtherAccount"
                    >
                        <span class="font-style-mip font-weight-600 ">
                            {{$t('SelectOtherAccount')}}
                        </span>
                    </button>

                    <div class="block-inv" v-if="pinIncorret"></div>
                </div>
            </b-form>
        </div>

        <div
            class="container-load d-flex flex-column align-items-center justify-content-center"
            v-else
        >
            <div
                class="load-content d-flex flex-column align-items-center justify-content-center"
            >
                <IconLoadSpinner class="load-icon"/>
                <span class="font-style-mip font-weight-600">
                    {{ $t('PINPleaseWait') }}
                </span>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import {
        BModal,
        BForm,
        BButton,
        BCol,
        BRow,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BSpinner,
        BAvatar
    } from 'bootstrap-vue';
    import IconLogout from '@/assets/images/pages/logout-icon.svg';
    import IconConnect from '@/assets/images/pages/connect-icon.svg';
    import IconClose from '@/assets/images/pages/close-button-icon.svg';
    import IconLoadSpinner from '@/assets/images/pages/load-icon.svg';

    export default {
        components:{
            BModal,
            BForm,
            BButton,
            BCol,
            BRow,
            BDropdown,
            BDropdownItem,
            BFormInput,
            BSpinner,
            IconLogout,
            IconConnect,
            IconClose,
            IconLoadSpinner,
            BAvatar,
        },
        data() {
            return {
                prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
                pin: '',
                maskInput: '••••',
                pinHide: '',
                pinIncorret: false,
                keyInputRender: 0,
                inputType: 'password',
                showLoading: false,
            }
        },
        props:{
            dadosUsuario: {
                type: Object,
            },
        },
        methods:{
            confirmPin(){
                this.showLoading = true;
                this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/switch-user`,
                    {
                        pin: this.pin,
                        user_guid: this.dadosUsuario.guid,
                        tokenId: JSON.parse(localStorage.getItem('infoAccountsLogin')).filter(item => item.guid == this.dadosUsuario.guid)[0].tokenId

                    }).then(response => {

                        localStorage.setItem('userData', JSON.stringify(response.data.userData));
                        localStorage.setItem('accessToken', response.data.accessToken);

                        this.getSites()


                    })
                     .catch(error => {
                        console.log(error)
                        this.pinIncorret = true;
                        this.showLoading = false
                        this.inputType = 'number';
                        this.keyInputRender++;
                        this.showLoading = false;
                        setTimeout(()=>{document.getElementById('input-pin').focus()}, 100)
                     })
            },
            getSites(){
                this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/tenant-site`).then( response => {
                    const sites = response.data
                    localStorage.setItem('sites', JSON.stringify(sites))

                    let currentSite = JSON.parse(localStorage.getItem('currentSite'))

                    const found = sites.find(site => site.guid == currentSite.guid)
                    if (!found){
                        localStorage.setItem('currentSite', JSON.stringify(sites[0]))
                    }

                    this.redirect()

                }).catch(error => {})
            },
            redirect(){
                if(this.$route.meta.resource == 'pcp') {
                    if(this.$route.name != 'resource-loading') {
                        window.location.pathname = `${this.prefix}/resource-loading`
                    } else {
                        window.location.reload();
                    }
                } else if(this.$route.meta.resource == 'operator') {
                    if(this.$route.name != 'operator') {
                        window.location.pathname = `${this.prefix}/operator`
                    } else {
                        window.location.reload();
                    }
                }
            },
            setCursorInputFinal(){
                const input = document.getElementById('input-pin');
                if(this.pin.length > 0) {
                    this.keyInputRender++;
                    setTimeout(()=>{input.focus()},100);
                }
            },
            selectOtherAccount(){
                this.$bvModal.hide('modal-insert-pin');
                this.$bvModal.show('modal-change-account');
            },
            filterInput(event){
                let input = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
                let keyPressed = event.key;
                let keyCode = (event.keyCode ? event.keyCode : event.which);

                if (!input.includes(keyPressed) && (keyCode != 8)) {
                    event.preventDefault();
                }
            },
            maxInput(value){
                if (value.length > 4) {
                    this.keyInputRender++;
                    setTimeout(()=>{document.getElementById('input-pin').focus()}, 100);
                    return;
                }

                if(value.length <= 4 && value != ''){
                    this.pin = value;
                } else if (value == '') {
                    this.pin = '';
                } else{
                    this.pin = this.pin;
                }

                if(this.pin.length == 3 && this.pinIncorret)
                    this.pinIncorret = false;


                if(this.pin.length == 4)
                    this.confirmPin();

                this.keyInputRender++;
                setTimeout(()=>{document.getElementById('input-pin').focus()}, 100);
            },
            closeModal(){
                this.$bvModal.hide('modal-insert-pin');
            }
        },
        watch: {
            pin(v) {
                this.maskInput = "";
                for (let i = 0; i < v.length; ++i){
                    this.maskInput += '•';
                }

                if(v == ''){
                    this.maskInput = '••••'
                }
            }
        },
        mounted(){
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                if(modalId == 'modal-change-account'){
                    this.pin = '';
                    this.maskInput = '••••';
                    this.pinIncorret = false;
                    this.keyInputRender = 0;
                    this.inputType = 'password';
                    this.showLoading = false;
                }
            })
        }
    }
</script>

<style lang="scss">

@media (min-width:768px) {
  #modal-insert-pin {
    padding: 0 !important;

    .modal-content,
    .modal-dialog {
      max-width: 746px !important;
    }
    .input-pin-helper{
            padding-left: 80px !important;
        .mask-input,
        .pin-invalid{
            letter-spacing: 100px !important;
        }
    }

  }
}

@media (max-width:480px) {
    #modal-insert-pin {
        padding: 0 !important;
        .modal-content,
        .modal-dialog {
            max-width: 350px !important;
        }
        .modal-content {
            min-height: 210px !important;
        }
        .modal-dialog {
            margin: 0 auto;
        }

        .container-load {
            min-height: 210px !important;
            .load-content {
                .load-icon {
                    height: 50px !important;
                    width: 50px !important;
                }

                span {
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
            }
        }

        .container-header {
            height: 106px !important;
            padding-bottom: 0;
            .title-modal {
                .account-photo {
                    height: 50px !important;
                    width: 50px !important;
                    margin-top: 16px !important;

                    span {
                        font-size: 18px !important;
                    }
                }

                span {
                    font-size: 12px !important;
                }
            }
        }

        .modal-body {
            .container-input-pin {
                height: 42px !important;
                .container-inputs-pin{
                    .input-pin-helper{
                        width: 100% !important;
                        padding-left: 5% !important;
                        .mask-input{
                            letter-spacing: 10vw !important;
                            font-size: 90px !important;
                        }
                        .pin-invalid {
                        letter-spacing: 10vw !important;
                        font-size: 22px !important;
                        }
                        .pin-valid {
                            font-size: 10px !important;
                        }
                    }

                    input {
                        height: 42px !important;
                    }

                }
            }

            .footer-modal {
                min-height: 60px !important;

                .alert-pin {
                    font-size: 12px !important;
                }

                .container-btn-enter-other-account {
                    .btn-confirm-pin {
                        width: 84px !important;
                        padding: 4px 0 !important;

                        span {
                            font-size: 12px !important;
                        }
                    }

                    .btn-enter-other-account {
                        width: 156px !important;
                        padding: 4px 0 !important;

                        span {
                            font-size: 12px !important;
                        }
                    }
                }

            }
        }
    }
}


#modal-insert-pin {
    .modal-content {
        position: relative !important;
        min-height: 349px;
    }

    .container-load {
        min-height: 349px;
        margin-top: -1.6rem;
        .load-content {
            .load-icon {
                height: 100px;
                width: 100px;
                margin-bottom: 8px;

                circle {
                    fill:#974900 !important;
                }
            }

            span {
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                color: #998F8A;
                padding: 0 10px;
                box-sizing: border-box;
            }
        }
    }
    .font-style-mip {
        line-height: 20px;
        font-weight: 400;
    }

    .font-weight-600 {
        font-weight: 600;
    }

    .modal-dialog {
        width: 95%;
        max-width: none;
    }

    .modal-content {
        width: 100%;
        right: 0 !important;
    }

    .modal-header {
         background: transparent;
         z-index: 10;
    }

    .container-header {
        position: relative !important;
        height: 168px;
        background-color: #fff;

        .title-modal {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .account-photo {
                margin-top: 40px;
                overflow: hidden;
                margin-right: 14px;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: #FF8D2E;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color:#512400;
                    font-size: 28px;
                    font-weight: 700;
                }
            }

            span {
                font-size: 14px;
                color: #998F8A;
                margin-top: 7.5px;
                word-break: break-all;
                padding: 0 28px 10px;
            }

            .btn-close-modal {
                display: none;
            }

            .close {
                &:focus {
                    transform: translate(0px, 0px) !important;
                }

                &:active {
                    transform: translate(0px, 0px) !important;
                }
            }
        }
    }

    .modal-body {
        padding: 0;
        overflow: hidden !important;
        margin-top: -1.6rem;

        .line-div {
            height: 1px;
            width: 100%;
            margin: 0;
            background:#CFC4BE;
        }

        .container-input-pin {
            height: 73px;
            position: relative;

            .container-inputs-pin {
                .input-pin-helper {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    letter-spacing: 13vw;
                    text-align: center;
                    font-size: 36px;
                    line-height: 50px;
                    padding-left: 8%;
                    font-size: 50px;

                    .mask-input {
                        font-size: 130px;
                    }

                    .pin-invalid {
                        color: #D32F2F !important;
                        letter-spacing: 13vw;
                        padding-left: 0 !important;
                    }

                    .color-mask-pin {
                        color: #4C4541;
                    }

                    .color-mask-placeholder {
                        color: #CFC4BE;
                    }

                    .--placeholder-invalid {
                        color: #D32F2F;
                    }
                }

                input {
                    height: 73px;
                    position: relative;
                    opacity: 0;
                    z-index: 2;
                }

            }
        }

        .footer-modal {
            min-height: 110px;
            position: relative;

            .block-inv {
                height: 0px;
                width: 100%;
                margin: 22.5px 0;
            }

            .alert-pin {
                position: absolute;
                top: 8px;
                color: #D32F2F;
                font-weight: 400;
                font-size: 14px;
            }

            .container-btn-enter-other-account {
                .btn-confirm-pin {
                    width: 135px;
                    box-sizing: border-box;
                    padding: 13px 28px;
                    border: none;
                    outline: none;
                    background: #974900 !important;
                    border-radius: 5px;

                    span {
                        font-size: 17px;
                        color: #FFFFFF;
                        letter-spacing: 0.0125em;
                    }
                }

                .btn-enter-other-account {
                    border: none;
                    background-color: #fff;
                    padding: 13px 18px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    transition: 1s;

                    span {
                        font-size: 17px;
                        color: #974900;
                    }

                    &:hover {
                        background-color: rgba(244, 129, 31, 0.35);
                    }
                }
            }

        }
    }
}

</style>
