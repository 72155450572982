<template>
  <div class="navbar-container d-flex content align-items-center">
    <ModalChangeAccount 
      :logout="logout"
      @atualizaContextoUsuario ="(value) => atualizaContextoUsuario(value)"
    />
    <ModalInsertAccountPin
      :dadosUsuario="contextoUsuario"
     />
    
    <LogoNavBar class="logo-icon"/>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav icon-menu d-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <IconMenu/>
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex horizontal-menu itens-menu">
      <operator-mode class="d-block operator-mode" />
      <site-select class="d-block site" />
      

      <div class="line"/>
      
      <div  class="header-navbar navbar-horizontal" v-if="this.$route.name.split('-')[0] != 'operator'">
        <horizontal-nav-menu />
      </div>
      
    </div>

    
    <b-navbar-nav class="nav align-items-center ml-auto " @mouseleave="toggleLanguageLeave">

      <operator-mode class="d-block  operator-mode tablet" />
      <site-select class="d-block  site tablet" />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
        :class="{ 'show': showMenu }"
      >
        <template #button-content>
          <div class=" d-flex user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.first_name }} {{ userData.last_name }}
            </p>
            <span class="user-status">{{ userData.job_role }}</span>
          </div>

          <b-avatar
            class="user-photo"
            v-if="userData.photo"
            size="40"
            variant="light-primary"
            :src="userData.photo"
          />

          <b-avatar variant="light-secondary" v-else>
            <span>{{ userData.first_name.substring(0,1).toUpperCase() }}{{ userData.last_name.substring(0,1).toUpperCase() }}</span>
          </b-avatar>
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'account-setting' }">
          <IconProfile/>
          <span>{{$t("Profile")}}</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" v-b-modal.modal-change-account>
          <IconReload/>
          <span>{{$t("ChangeAccount")}}</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'support' }">
          <IconSupport/>
          <span>{{$t("Support")}}</span>
        </b-dropdown-item>

        <a class="navlink-lang w-100" @mouseenter="toggleLanguageEnter" @mouseleave="toggleExpandLanguage">
          <locale type="userDropdown" :class="{ 'show': expandLanguage }"/>
        </a>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <IconLogout/>
          <span>{{$t("Logout")}}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import OperatorMode from '@core/layouts/components/app-navbar/components/OperatorMode.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import SiteSelect from '@core/layouts/components/app-navbar/components/SiteSelect.vue'
import LogoNavBar from '../../assets/images/logo/logo-navbar-icon.svg'
import HorizontalNavMenu from '../../../src/@core/layouts/layout-horizontal/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import ModalChangeAccount from '@/@core/components/b-modal/ModalChangeAccount/ModalChangeAccount.vue'
import IconProfile from '@/assets/images/pages/profile-icon.svg'
import IconReload from '@/assets/images/pages/reload-icon.svg'
import IconSupport from '@/assets/images/pages/support-icon.svg'
import IconLogout from '@/assets/images/pages/logout-icon.svg'
import IconMenu from '@/assets/images/pages/menu-icon.svg'
import ModalInsertAccountPin from '@/@core/components/b-modal/ModalChangeAccount/ModalInsertAccountPin.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
    OperatorMode,
    Locale,
    SiteSelect,
    LogoNavBar,
    HorizontalNavMenu,
    ModalChangeAccount,
    IconProfile,
    IconReload,
    IconSupport,
    IconLogout,
    IconMenu,
    ModalInsertAccountPin,
},
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data(){
    return {
      userData : JSON.parse(localStorage.getItem('userData')),
      prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix, 
      showMenu: false,
      expandLanguage: false,
      pcpAccess: JSON.parse(localStorage.getItem('userData')).ability.find( element => element.action === 'manage') || JSON.parse(localStorage.getItem('userData')).ability.find( element => element.subject === 'pcp'),
      contextoUsuario:{}
    }
  },
  methods: {
    atualizaContextoUsuario(value){
      this.contextoUsuario = value
    },
    logout(){
      let lista = JSON.parse(localStorage.getItem('infoAccountsLogin'));
      let guid = this.userData.guid
      let indexGuidAtual = lista.map(item => item.guid).indexOf(guid);
      lista.splice(indexGuidAtual, 1);

      localStorage.setItem('infoAccountsLogin', JSON.stringify(lista));

      useJwt.logout(this.$router.currentRoute.params.prefix).then(response =>{
          localStorage.removeItem('userData')
          localStorage.removeItem('sites')

          // Reset ability
          this.$ability.update(initialAbility)

          // Redirect to login page
          this.$router.push({ name: 'login' }).catch(() => {});
      }).catch(error => {
          // Remove accessToken from localStorage
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('sites')

          // Reset ability
          this.$ability.update(initialAbility)
           
          // Redirect to login page
          this.$router.push({ name: 'login' })
      })
    },
    toggleOperatorMode(){
      // this.isNavMenuHidden = !this.isNavMenuHidden
      return this.$route.name.split('-')[0] == 'operator' ? this.$router.push({name: 'home', params: {prefix: this.prefix}}) : this.$router.push({name: 'operator', params: {prefix: this.prefix}})
    },   
    toggleLanguageEnter(){    
      this.showMenu=true
    }, 
    toggleLanguageLeave(){    
      this.showMenu=false
    },     
    toggleExpandLanguage(){    
      this.expandLanguage=false
    }, 
  },
  setup() {
    const { skin, isNavMenuHidden } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark, isNavMenuHidden }
  },
}
</script>
<style lang="scss" >
.navlink-lang { 
  overflow: hidden;
  li {
    padding-left: 0;
    padding-right: 0;
    .test {
      padding: 0 1.28rem;
    }
    a {
      left: initial !important;
    }

    .dropdown-item {
      width: 13rem !important;
      padding-right: 0;
    }
  }
}

.navbar {
  .navbar-container{
    .dropdown-user{
      .nav-link{
        .user-name{
          font-weight: 400 !important;
          font-size: 14px;
          line-height: 20px;
          color: #4C4541;
        }
        .user-status{
          font-weight: 400 !important;
          font-size: 12px;
          line-height: 16px;
          color: #998F8A;
        }
        .user-photo{
          width: 38px !important;
          height: 38px !important;
        }
      }
      .dropdown-menu{
        .nav-locale{
          flex-direction: column;
          &.en{
            .nav-link{
              left: -28px !important;
            }
          }
          .nav-link{
            left: -14px;
            svg{
              right: -25px;
              top: 1px !important;
            }
          }
          .dropdown-menu{
           width: 166px;
           padding-bottom: 0;
           background: transparent;
           top: 5px;
           left: 0 !important;
           animation: none !important;
            .item-locale{
              a{
               margin-bottom: 0;
               background: #fff !important;
              }
              &:hover{
                a{
                 background: #FFEDE2 !important;

                }
              }
              
            }
          }
          &.show{
            padding-bottom: 0;
            background: #FFDBC4 !important;

          }
        }
        .dropdown-item{
          span{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998F8A;
            margin-left: 9px;
          }
          svg{
            path{
              fill: #998F8A;
            }
          }
          &:hover{
            background-color: #FFEDE2;
            span{
              color: #974900;
            }
            svg{
              path{
                fill: #974900 !important;
              }
            }
            a{
              span{
                color: #974900 !important;
              }
            }
          }
          .nav-link{
            padding: 0;
          }
        }
      }
    }
    .bookmark-wrapper{

      .operator-mode{
        margin-left: 15px;
        border-radius: 5px;
        .nav-link{
          padding: 9px 12px;
          color: #998F8A !important;
        }
        &:hover{
          background-color: #FFEDE2;
          .nav-link{
            svg{
              path{
                fill: #974900;
              }
            }
          } 
        }
      }
      .site{
        padding: 9px 12px;
        border-radius: 5px;
        .nav-link{
          padding: 0 ;
          color: #998F8A !important;
        }
        &:hover{
          background-color: #FFEDE2;
          .nav-link{
            
            .icon-arrow-down{
              path{
                fill: #974900;
              }
            }
            .icon-database{
              path{
                stroke: #974900;
              }
            }
          } 
        }
      }
      .line{
        width: 2px;
        height: 30px;
        background: #ECE0DB;
        margin: 0 26px;
      }
      .header-navbar.navbar-horizontal{
        .navbar-container{
          padding: 0 !important;
          .nav-item{
            padding: 0 !important;
            &:hover{
              .nav-link{
                background: #FFEDE2 !important;
              }
              span{
                color: #974900 !important;
              }
              svg{
                path{
                  fill: #974900 !important;
                }
              }
            }
            &.active{
              .nav-link{
                background: #974900 !important;
                box-shadow:none !important;
              }
              span{
                color: #ffff !important;
              }
              svg{
                path{
                  fill: #ffff !important;
                }
              }
              .dropdown-menu{
                .dropdown-submenu{
                  &.sidebar-group-active{
                    .dropdown-toggle{
                      background-color: #FFDBC4 !important;
                      span{
                        color: #974900 !important;
                      }
                      svg{
                        path{
                          fill:#974900 !important;;
                        }
                      }
                    }
                  }
                }
                .dropdown-item.router-link-active{
                  background-color: #FFDBC4 !important;
                  span{
                    color: #974900 !important;
                  }
                  svg{
                    path{
                      fill:#974900 !important;;
                    }
                  }
                }
                
                
              }
            }
            &:focus{
              .nav-link{
                background: #FFDBC4 !important;
              }
              span{
                color: #974900 !important;
              }
              svg{
                path{
                  fill: #974900 !important;
                }
              }
            }
            .nav-link{
              display: flex;
              align-items:center !important;
              svg{
                path{
                  fill: #998F8A ;
                }
              }
              span{
                color:#998F8A;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
              }
            }
            .dropdown-menu{
              margin: 0 !important;
              padding: 0;
              box-shadow: none !important;
              background-color: transparent !important;
              ul{
                padding: 8px 0 !important;
                box-shadow: 0 5px 25px rgb(34 41 47 / 10%);
                margin-top: 4px;
                background-color: #ffff !important;
                border-radius: 6px ;
                .dropdown-item{
                  span{
                    color: #998F8A !important;
                  }
                  svg{
                    path{
                      fill: #998F8A !important;
                    }
                  }
                  &:hover{
                    background-color: #FFEDE2 !important;
                    .ooe-icon{
                      path{
                        stroke: #974900;
                      }
                    }
                    span{
                      color: #974900 !important;
                    }
                    svg{
                      path{
                        fill:#974900 !important;
                      }
                      
                    }
                  }
                  &:focus{
                    background-color: #FFDBC4 !important;
                    span{
                      color: #974900 !important;
                    }
                    svg{
                      path{
                        fill:#974900 !important;;
                      }
                      
                    }
                  }
                }
                .dropdown-menu{
                  box-shadow: 0 5px 25px rgb(34 41 47 / 10%) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1252px) {
  .logo-icon, .horizontal-menu.itens-menu {
    display: none !important;
  }
  .icon-menu{
    display: block !important;
  }

}
@media (min-width: 1253px) {
  .operator-mode.tablet, .site.tablet{
    display: none !important;
  }
  
}

@media (max-width: 480px) {
  .operator-mode.tablet, .site.tablet{
    display: none !important;
  }
  .dropdown-user .dropdown-menu {
    margin-top: 0 !important;
  }
  
}


</style>